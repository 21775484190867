import { fetchNgwLayerFeature } from '@nextgis/ngw-kit';
import { fixUrlStr } from '@nextgis/utils';
import { area } from '@turf/area';
import { featureCollection } from '@turf/helpers';
import intersect from '@turf/intersect';
import { useCallback } from 'react';

import { useConfigProvider } from '../../../ConfigProvider';
import { extractError } from '../../../utils/extractError';
import { formatPerfTime } from '../../../utils/formatPerfTime';
import { normalizeGeometry } from '../../../utils/normalizeGeometry';
import { polygonFromCoords } from '../../../utils/polygonFromCoords';
import { createDateRow as createDateRow } from '../../../utils/sheetStyleUtils';
import { useIdentifyContext } from '../IdentifyContext';

import type { Feature, MultiPolygon, Polygon } from 'geojson';

import type {
  DynamicFeatureProperties,
  IdentifyResultItem,
  IdentifyResultProps,
} from '../../../interfaces';

const NON_POLY_RESOURCES: number[] = [];

export function useReport() {
  const {
    bufferSize,
    bufferCoords: coords,
    identifyType,
    reportLoading,
    identifyResults,
    identifySourceType,
    reportAbortControl,
    setReportLoading,
  } = useIdentifyContext();

  const {
    logger,
    baseUrl,
    connector,
    identifySourceItems,
    customReportSheets,
  } = useConfigProvider();

  const calculateIntersection = useCallback(
    async ({
      resultItem,
      polyToIntersect,
    }: {
      resultItem: IdentifyResultItem;
      polyToIntersect: Feature<Polygon>;
    }) => {
      const { resourceId, featureId } = resultItem;
      const item = await fetchNgwLayerFeature<Polygon | MultiPolygon>({
        resourceId,
        connector,
        featureId,
        cache: true,
        signal: reportAbortControl.current?.signal,
      });
      let intersectArea = 0;
      let itemsToIntersect: Polygon[] = [];
      if (item.geometry.type === 'Polygon') {
        itemsToIntersect = [item.geometry];
      } else if (item.geometry.type === 'MultiPolygon') {
        for (const coordinates of item.geometry.coordinates) {
          itemsToIntersect.push({
            type: 'Polygon',
            coordinates,
          });
        }
      } else {
        // Optimization to avoid next intersection calculation for this resource id
        // There are no faster way to get webmap layer item geometry type
        NON_POLY_RESOURCES.push(resourceId);
      }

      for (const toIntersect of itemsToIntersect) {
        try {
          const intersected = intersect(
            featureCollection([
              polyToIntersect,
              { type: 'Feature', geometry: toIntersect, properties: {} },
            ]),
          );
          if (intersected) {
            intersectArea += area(intersected);
          }
        } catch (er) {
          logger?.error(`Intersection error while Report`, {
            operationId: 'report-intersection-error',
            data: { error: extractError(er), resourceId, featureId },
          });
          throw new Error(
            `${(er as Error)?.message} -- resourceId: ${resourceId}; featureId: ${featureId}`,
          );
        }
      }

      if (intersectArea) {
        resultItem.feature.fields['_intersectionArea'] = (
          intersectArea / 10000
        ).toFixed(3);
      }
    },
    [connector, logger, reportAbortControl],
  );

  const prepareDynamicData = useCallback(
    (resultBySource: IdentifyResultProps): (string | number)[][] => {
      const allLayerYears: number[] = [];

      for (const r of resultBySource.identifyResult) {
        const years = { ...r.feature.fields };
        delete years.NAME;

        for (const y in years) {
          const isYear = y.length === 4 && /^-?\d+$/.test(y);
          if (isYear) {
            const newYear = Number(y);
            if (!allLayerYears.includes(newYear)) {
              allLayerYears.push(newYear);
            }
          }
        }
      }

      allLayerYears.sort();

      const rows: (string | number)[][] = [
        ['Динамика'],
        [''],
        ['Наименование / Район', ...allLayerYears.map(String)],
        [''],
      ];

      for (const layer of resultBySource.identifyLayers) {
        const ngwResourceUrl = fixUrlStr(`${baseUrl}/resource/${layer.id}`);
        const layerNameRow = [layer.name, '', '', '', '', ngwResourceUrl];

        rows.push(layerNameRow);

        for (const r of resultBySource.identifyResult) {
          if (r.layerName === layer.name) {
            const { NAME, ...years } = {
              ...r.feature.fields,
            } as DynamicFeatureProperties;
            const layerFeatureRow = [NAME];
            for (const y of allLayerYears) {
              const existYear = years[y] ?? '';
              layerFeatureRow.push(String(existYear));
            }
            rows.push(layerFeatureRow);
          }
        }
      }

      return rows;
    },
    [baseUrl],
  );

  const prepareData = useCallback(
    ({ items }: { items: IdentifyResultItem[] }) => {
      const fromSource = identifySourceItems.find(
        (x) => x.value === identifySourceType,
      );
      const addHeaders = [];
      const addProps = [];
      const fields = fromSource?.fields;
      if (fields) {
        for (const f of fields) {
          addHeaders.push(f.displayName);
          addProps.push(f.keyname);
        }
      }
      const isPolygon = coords && coords.length > 1;
      const headers: (string | number)[] = [
        'Номер',
        'Наименование слоя',
        ...addHeaders,
        isPolygon ? 'Площадь пересечения, га' : '',
        'Ресурс',
      ].filter(Boolean);

      const rows: (string | number)[][] = [];

      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const { feature, resourceId, featureId } = item;
        const ngwUrl = fixUrlStr(
          `${baseUrl}/resource/${resourceId}/feature/${featureId}`,
        );
        const row = [
          i,
          item.layerName,
          ...addProps.map((x) => feature.fields[x]),
          isPolygon ? feature.fields._intersectionArea : 'UNSET',
          ngwUrl,
        ].filter((x) => x !== 'UNSET');
        rows.push(row);
      }

      return [headers, ...rows];
    },
    [baseUrl, coords, identifySourceItems, identifySourceType],
  );

  const writeBook = useCallback(async () => {
    const dt = new Date();

    if (!identifyResults) return;

    const data: (string | number)[][] = [];
    let dynamicData: (string | number)[][] | null = null;
    let fistTable = true;
    for (const resultBySource of identifyResults) {
      const { identifyResult, identifySourceType } = resultBySource;
      const sourceData = prepareData({ items: identifyResult });
      const label = identifySourceItems.find(
        (x) => x.value === identifySourceType,
      )?.label;
      if (label) {
        const labelWrapper = [[label], ['']];
        if (!fistTable) {
          labelWrapper.unshift(['']);
        } else {
          fistTable = false;
        }
        data.push(...[...labelWrapper, ...sourceData]);
      }
      if (identifySourceType === 'dynamic') {
        dynamicData = prepareDynamicData(resultBySource);
      }
    }
    data.push(['']);
    const dateRow = createDateRow();
    data.push(...[dateRow]);

    const XLSX = await import('sheetjs-style');

    const wb = XLSX.utils.book_new();

    if (customReportSheets) {
      for (const { name, loader } of customReportSheets) {
        const customSheet = await loader({ dt });
        XLSX.utils.book_append_sheet(wb, customSheet, name);
      }
    }

    const intersectionSheet = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, intersectionSheet, 'Пересечение');

    if (dynamicData) {
      const dynamicSheet = XLSX.utils.aoa_to_sheet(dynamicData);
      XLSX.utils.book_append_sheet(wb, dynamicSheet, 'Динамика');
    }

    if (coords) {
      let tableCoordinates: number[][];
      const headers: string[] = ['Долгота', 'Широта'];
      if (identifyType === 'click') {
        const center = (await import('@turf/center')).default;
        const centerFeature = center(polygonFromCoords(coords));
        tableCoordinates = [
          [...centerFeature.geometry.coordinates, bufferSize],
        ];
        headers.push('Буфер');
      } else {
        tableCoordinates = coords;
      }

      const coordinatesData = XLSX.utils.aoa_to_sheet([
        headers,
        ...tableCoordinates,
        [''],
        createDateRow(dt),
      ]);
      XLSX.utils.book_append_sheet(wb, coordinatesData, 'Координаты участка');
    }

    const fileName = `Отчёт пересечения.xlsx`;

    XLSX.writeFile(wb, fileName);
  }, [
    identifyResults,
    customReportSheets,
    coords,
    prepareData,
    identifySourceItems,
    prepareDynamicData,
    identifyType,
    bufferSize,
  ]);

  const makeReport = useCallback(
    async ({ onProgress }: { onProgress?: (percent: number) => void }) => {
      if (!identifyResults) return;

      const logData = {
        geom: coords && polygonFromCoords(coords),
        coordinates: coords,
        identifySourceItems: identifyResults.map((x) => x.identifySourceType),
        identifyResultsLength: identifyResults.reduce(
          (a, b) => a.concat(b.identifyResult),
          [] as IdentifyResultItem[],
        ).length,
      };
      logger?.info('Making report begin', {
        operationId: 'report-start',
        data: logData,
      });
      const start = performance.now();

      setReportLoading(true);

      try {
        if (identifyResults && coords && coords.length > 1) {
          const polyToIntersect = normalizeGeometry(polygonFromCoords(coords));

          const identifyResultsItems = identifyResults.reduce<
            IdentifyResultItem[]
          >((acc, res) => acc.concat(res.identifyResult), []);

          let ready = 0;

          for (const resultItem of identifyResultsItems) {
            if (reportAbortControl.current?.signal?.aborted) {
              throw new DOMException('AbortError', 'AbortError');
            }
            if (!NON_POLY_RESOURCES.includes(resultItem.resourceId)) {
              await calculateIntersection({
                resultItem,
                polyToIntersect,
              });
            }

            if (onProgress) {
              onProgress((ready++ / identifyResultsItems.length) * 100);
            }
          }
        }

        await writeBook();

        const duration = performance.now() - start;
        logger?.info(`Making report completed in ${formatPerfTime(duration)}`, {
          duration,
          operationId: 'report-success',
          data: logData,
        });
      } catch (er) {
        const duration = performance.now() - start;
        logger?.error(
          `Report creation failed with an error after ${formatPerfTime(duration)}`,
          {
            duration,
            operationId: 'report-error',
            data: { error: extractError(er), ...logData },
          },
        );
        throw er;
      } finally {
        setReportLoading(false);
      }
    },
    [
      identifyResults,
      coords,
      logger,
      setReportLoading,
      writeBook,
      reportAbortControl,
      calculateIntersection,
    ],
  );

  return {
    reportLoading,
    makeReport,
  };
}
