import styled from 'styled-components';

import type { ReactNode } from 'react';

interface PanelTextInfoProps {
  text?: string;
  children?: ReactNode;
}

const PanelTextWrapper = styled.span({
  fontSize: '12pt',
});

export function PanelText({ text, children }: PanelTextInfoProps) {
  return <PanelTextWrapper>{text || children}</PanelTextWrapper>;
}
