import { LoadingOutlined } from '@ant-design/icons';
import { useNgwMapContext } from '@nextgis/react-ngw-map';
import { Space, Spin } from 'antd';
import { useCallback, useEffect, useState } from 'react';

import { useConfigProvider } from '../../ConfigProvider';
import { baselayersPrefix } from '../../constants';
import { formatPerfTime } from '../../utils/formatPerfTime';
import { getIdentifyLayers } from '../../utils/getIdentifyLayers';

import { PointIdentifyPanel } from './mode-panels/PointPanel/PointPanel';
import { TableIdentifyPanel } from './mode-panels/TablePanel/TablePanel';
import { addBaselayers } from './utils/addBaselayers';
import { drawLayerOnMap } from './utils/drawLayerOnMap';
import { IdentifyBlock } from './IdentifyBlock';
import { IdentifyProvider, useIdentifyContext } from './IdentifyContext';
import { IdentifyControl } from './IdentifyControl';
import { IdentifySettings } from './IdentifySettings';
import { PanelText } from './PanelText';

import type { IdentifyLayerItem, IdentifySource } from '../../interfaces';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function IdentifyTypeControl() {
  const { identifyType } = useIdentifyContext();
  switch (identifyType) {
    case 'click':
      return <PointIdentifyPanel />;
    case 'table':
      return <TableIdentifyPanel />;
    default:
      return <PanelText>Не выбран способ идентификации</PanelText>;
  }
}

export function IdentifyPanel() {
  return (
    <IdentifyProvider>
      <IdentifyPanelContent />
    </IdentifyProvider>
  );
}

export function IdentifyPanelContent() {
  const { ngwMap } = useNgwMapContext();
  const {
    coords,
    bufferSize,
    bufferCoords,
    identifyType,
    identifyLayers,
    identifySources,
    setIdentifyResults,
    setIdentifyLayers,
    setCoords,
    abort,
  } = useIdentifyContext();

  const { webMapId, connector, maxBaselayersDisplay, baseUrl, logger } =
    useConfigProvider();
  const layerId = 'polygon-table';

  const [sourceLoading, setSourceLoading] = useState(false);

  const drawLayer = useCallback(async () => {
    drawLayerOnMap({
      coords,
      bufferCoords: bufferSize ? bufferCoords : null,
      ngwMap,
      layerId,
      identifyType,
    });
  }, [bufferCoords, bufferSize, coords, identifyType, ngwMap]);

  const removeBaselayers = useCallback(() => {
    const layers = ngwMap.getLayers();
    for (const l of layers) {
      if (l.startsWith(baselayersPrefix)) {
        ngwMap.removeLayer(l);
      }
    }
  }, [ngwMap]);

  const removeOverlays = useCallback(() => {
    ngwMap.removeLayer(layerId);
  }, [ngwMap]);

  const onSourceChange = useCallback(
    async (sources: IdentifySource[], signal: AbortSignal) => {
      if (sources.length) {
        await Promise.resolve();

        const start = performance.now();

        setIdentifyLayers([]);

        const identifyLayers_: IdentifyLayerItem[] = [];

        try {
          setSourceLoading(true);
          const res = await connector.getResourceOrFail(webMapId, {
            signal,
          });
          const webmap = res.webmap;
          if (!webmap) {
            throw new Error('Resource is not a webmap');
          }

          for (const source of sources) {
            const sourceIdentifyLayers = getIdentifyLayers(webmap, source.path);

            for (const identifyLayer of sourceIdentifyLayers) {
              identifyLayers_.push({ source: source.value, ...identifyLayer });
            }
          }
        } catch (er) {
          if ((er as Error).name !== 'AbortError') {
            throw er;
          }
        } finally {
          setSourceLoading(false);
        }
        const duration = performance.now() - start;
        if (identifyLayers_.length) {
          setIdentifyLayers(identifyLayers_);
          logger?.info(
            `Identify layers loaded at ${formatPerfTime(duration)}`,
            {
              duration,
              operationId: 'identify-fetch-layers',
              data: {
                identifyLayersLength: identifyLayers_.length,
                sources: sources.map((x) => x.value),
              },
            },
          );
        } else {
          logger?.info(
            `Identify layers loaded error at ${formatPerfTime(duration)}`,
            {
              duration,
              operationId: 'identify-fetch-layers-error',
              data: {
                identifyLayersLength: identifyLayers_.length,
                sources: sources.map((x) => x.value),
              },
            },
          );
        }
      }
    },
    [connector, logger, setIdentifyLayers, webMapId],
  );

  useEffect(() => {
    const changeSourceAbortControl = new AbortController();

    removeOverlays();
    removeBaselayers();
    onSourceChange(identifySources, changeSourceAbortControl.signal);
    return () => {
      changeSourceAbortControl.abort();
    };
  }, [identifySources, onSourceChange, removeBaselayers, removeOverlays]);

  useEffect(
    function prepareIdentify() {
      const isSourceVisible = identifySources.every(
        (x) => x.showLayers ?? true,
      );
      if (isSourceVisible && identifyLayers) {
        addBaselayers({
          maxBaselayersDisplay,
          identifyLayers,
          connector,
          baseUrl,
          ngwMap,
        });
      }
      return () => {
        removeBaselayers();
      };
    },
    [
      ngwMap,
      baseUrl,
      connector,
      identifyLayers,
      identifySources,
      maxBaselayersDisplay,
      removeBaselayers,
    ],
  );

  useEffect(() => {
    return () => {
      abort();
      setCoords(null);
    };
  }, [abort, setCoords]);

  useEffect(() => {
    abort();
    setCoords(null);
  }, [abort, identifyType, setCoords]);

  useEffect(() => {
    drawLayer();
  }, [drawLayer, identifyLayers]);

  useEffect(() => {
    abort();
    setIdentifyResults(null);
  }, [identifyLayers, coords, bufferSize, abort, setIdentifyResults]);

  if (sourceLoading) {
    return (
      <Space direction="horizontal">
        <PanelText>Загрузка списка ресурсов</PanelText>
        <Spin indicator={antIcon} />
      </Space>
    );
  }

  if (!identifyLayers.length) {
    return (
      <PanelText>Не удалось загрузить список слоёв для идентификации</PanelText>
    );
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <IdentifyControl />
      <IdentifyTypeControl />
      <IdentifySettings />
      <IdentifyBlock />
    </Space>
  );
}
