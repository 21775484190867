import { StopOutlined } from '@ant-design/icons';
import { Alert, Button, Progress, Space } from 'antd';
import { useMemo } from 'react';

import { useIdentify } from './hooks/useIdentify';
import { useIdentifyContext } from './IdentifyContext';
import { IdentifyResult } from './IdentifyResult';
import { PanelText } from './PanelText';

export function IdentifyBlock() {
  const {
    bufferCoords,
    identifyError,
    identifyLoading,
    identifyResults,
    intersectionNotAllowed,
    abort,
  } = useIdentifyContext();

  const resultExist = useMemo(
    () => identifyResults && identifyResults.length,
    [identifyResults],
  );

  const { startIdentify } = useIdentify();

  if (identifyLoading !== undefined) {
    return (
      <Space direction="horizontal">
        <PanelText>Поиск пересечений</PanelText>
        <Progress steps={10} percent={Math.ceil(identifyLoading)} />
        <Button size="small" icon={<StopOutlined />} onClick={abort}></Button>
      </Space>
    );
  }

  if (!bufferCoords) {
    return null;
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {!identifyResults ? (
        <>
          <Button
            block
            disabled={!!intersectionNotAllowed}
            onClick={startIdentify}
          >
            Найти пересечения
          </Button>
          {!!intersectionNotAllowed && (
            <Alert
              type="warning"
              message={
                typeof intersectionNotAllowed === 'string'
                  ? intersectionNotAllowed
                  : 'Невозможно выполнить пересечение'
              }
            />
          )}
        </>
      ) : resultExist ? (
        <IdentifyResult />
      ) : (
        <PanelText>Пересечений не найдено</PanelText>
      )}
      {identifyError && <Alert type="error" message={identifyError} />}
    </Space>
  );
}
