import { StopOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { Alert, Button, Modal, Progress, Space, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';

import { useConfigProvider } from '../../ConfigProvider';

import { useReport } from './hooks/useReport';
import { useIdentifyContext } from './IdentifyContext';
import { PanelText } from './PanelText';

interface ShowSourceListItem {
  label: string;
  itemsLength: number;
}

const { Text } = Typography;

export function IdentifyResult() {
  const {
    identifyResults,
    bufferCoords: coords,
    reportAbortControl,
  } = useIdentifyContext();
  const { identifySourceItems } = useConfigProvider();

  const { makeReport } = useReport();

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState<Error>();

  const identifyResultsLength = identifyResults?.length;

  const onShowCatClick = () => {
    if (!identifyResults) {
      return;
    }
    const showSourceItems: ShowSourceListItem[] = [];
    for (const x of identifyResults) {
      const fromSource = identifySourceItems.find(
        (y) => y.value === x.identifySourceType,
      );
      if (fromSource) {
        showSourceItems.push({
          label: fromSource.label,
          itemsLength: x.identifyResult.length,
        });
      }
    }

    Modal.info({
      title: 'Категории пересечения',
      content: (
        <>
          <ul>
            {showSourceItems.map((x) => (
              <li key={x.label}>
                {x.label} - {x.itemsLength}
              </li>
            ))}
          </ul>
        </>
      ),
    });
  };

  const identifyResultsItemsLength = identifyResults?.reduce(
    (a, b) => a + b.identifyResult.length,
    0,
  );

  const report = useCallback(async () => {
    setLoading(true);
    setError(undefined);

    if (!coords || !identifyResults) return;
    try {
      await makeReport({
        onProgress: setProgress,
      });
    } catch (er) {
      if ((er as Error).name !== 'AbortError') {
        setError(er as Error);
      }
    } finally {
      setLoading(false);
    }
  }, [coords, identifyResults, makeReport]);

  const abort = useCallback(() => {
    if (reportAbortControl.current) {
      reportAbortControl.current.abort();
    }
    reportAbortControl.current = undefined;
  }, [reportAbortControl]);

  useEffect(() => {
    return abort;
  }, [abort]);

  function ManySourcesText() {
    return (
      <>
        в {identifyResultsLength} категориях (
        <a onClick={onShowCatClick}>список</a>)
      </>
    );
  }

  return (
    <>
      <div>
        <PanelText>
          <Text type="success">
            Пересечения: {identifyResultsItemsLength}
            {identifyResultsLength && identifyResultsLength > 1 && (
              <>
                {' '}
                <ManySourcesText />
              </>
            )}
          </Text>
        </PanelText>
      </div>

      {error && error.message && <Alert type="error" message={error.message} />}

      {loading ? (
        <Space direction="horizontal" style={{ width: '100%' }}>
          <PanelText>Подготовка отчёта</PanelText>
          <Progress percent={Math.ceil(progress)} />
          <Button size="small" icon={<StopOutlined />} onClick={abort}></Button>
        </Space>
      ) : (
        <Button
          onClick={report}
          loading={loading}
          icon={<DownloadOutlined />}
          block
          style={{ marginTop: '1rem' }}
        >
          Сформировать отчёт
        </Button>
      )}
    </>
  );
}
