import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Col, Radio, Row, Select, Space, Tooltip, Typography } from 'antd';
import { useRef } from 'react';

import { useConfigProvider } from '../../ConfigProvider';
import { identifyTypeItems } from '../../constants';

import { useIdentifyContext } from './IdentifyContext';

import type { IdentifySourceType, IdentifyType } from '../../interfaces';

const { Text } = Typography;

export function IdentifyControl() {
  const {
    isLoading,
    identifyType,
    identifyLayers,
    identifySourceType,
    setIdentifySourceType,
    setIdentifyType,
  } = useIdentifyContext();

  const { identifySourceItems, maxBaselayersDisplay, logger } =
    useConfigProvider();

  const onIdentifySourceChange = (val: IdentifyType) => {
    setIdentifyType(val);
    logger?.info(`Identify source type changed to '${val}'`, {
      operationId: 'identify-source-change',
      data: { identifySourceType: val },
    });
  };

  const onIdentifyInputChange = (val: IdentifySourceType) => {
    setIdentifySourceType(val);
    logger?.info(`Identify input type changed to '${val}'`, {
      operationId: 'identify-input-change',
      data: { identifyType: val },
    });
  };

  const identifyLayersLength = useRef<
    Partial<Record<IdentifySourceType, number>>
  >({});

  return (
    <Row wrap={false}>
      <Col span={12} style={{ textAlign: 'center' }}>
        <Text>Введите координаты</Text>
        <Radio.Group
          disabled={isLoading}
          value={identifyType}
          onChange={(e) => onIdentifySourceChange(e.target.value)}
          style={{ marginTop: '8px' }}
        >
          {identifyTypeItems.map(({ value, label }) => (
            <Radio.Button value={value} key={value}>
              {label}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Col>

      <Col span={12} style={{ textAlign: 'center' }}>
        <Text>Выберете тип данных</Text>
        <Select
          disabled={isLoading}
          value={identifySourceType}
          onChange={onIdentifyInputChange}
          style={{ width: '198px', marginTop: '8px' }}
          placeholder="Выберите данные"
        >
          {identifySourceItems
            .filter(({ selectable }) => selectable ?? false)
            .map(({ label, value, showLayers }) => {
              let title = '';
              let visiblePercent = identifyLayersLength.current[value] ?? 0;
              if (value === identifySourceType) {
                if (identifyLayers.length > maxBaselayersDisplay) {
                  visiblePercent = Math.floor(
                    (maxBaselayersDisplay / identifyLayers.length) * 100,
                  );
                } else {
                  visiblePercent = 100;
                }
                identifyLayersLength.current[value] = visiblePercent;
              }
              if (visiblePercent) {
                title = `${visiblePercent}%`;
              }
              return (
                <Select.Option key={value} value={value}>
                  <Space>
                    {showLayers !== undefined && !showLayers ? (
                      <EyeInvisibleOutlined />
                    ) : (
                      <Tooltip title={title}>
                        <EyeOutlined
                          style={{ opacity: (visiblePercent || 100) / 100 }}
                        />
                      </Tooltip>
                    )}
                    {label}
                  </Space>
                </Select.Option>
              );
            })}
        </Select>
      </Col>
    </Row>
  );
}
