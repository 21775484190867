import type { IdentifyType, SelectItem } from './interfaces';

export const baselayersPrefix = 'baselayers';

export const INTERSECTION_LAYER_ORDER = 1000;
export const INTERSECTED_LAYER_ORDER = 100;
export const identifyTypeItems: SelectItem<IdentifyType>[] = [
  { label: 'Из таблицы', value: 'table' },
  { label: 'В точке', value: 'click' },
];
