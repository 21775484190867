import { getSquarePolygonCoordinates, isObject } from '@nextgis/utils';
import React, { useCallback, useRef } from 'react';

import { useConfigProvider } from '../../../ConfigProvider';
import { extractError } from '../../../utils/extractError';
import { formatPerfTime } from '../../../utils/formatPerfTime';
import { polygonFromCoords } from '../../../utils/polygonFromCoords';
import { useIdentifyContext } from '../IdentifyContext';
import { identifyChunk } from '../utils/identifyChank';

import type { NgwIdentifyItem } from '@nextgis/ngw-kit';

import type {
  IdentifyLayerItem,
  IdentifyResultProps,
  IdentifySourceType,
} from '../../../interfaces';

export function useIdentify() {
  const {
    abort,
    bufferCoords,
    identifyType,
    identifyLayers,
    identifySources,
    identifyAbortControl,
    setIdentifyLoading,
    setIdentifyResults,
    setIdentifyError,
  } = useIdentifyContext();
  const IDENTIFY_REQUEST_COUNT = useRef(1);

  const { baseUrl, connector, logger } = useConfigProvider();

  const startIdentify = useCallback(async () => {
    abort();
    setIdentifyResults(null);
    if (!bufferCoords) {
      return;
    }
    let geom = [...bufferCoords];
    identifyAbortControl.current = new AbortController();
    const signal = identifyAbortControl.current.signal;

    if (geom.length === 1) {
      const [lng, lat] = geom[0];
      geom = getSquarePolygonCoordinates(lng, lat, 0.01);
    }
    const log: { data: Record<string, unknown> } = {
      data: {
        geom: polygonFromCoords(geom),
        identifySources,
        identifyType,
      },
    };
    logger?.info('Identify started', { ...log, operationId: 'identify-start' });
    const requestId = ++IDENTIFY_REQUEST_COUNT.current;
    setIdentifyError(undefined);
    setIdentifyLoading(0);
    const start = performance.now();

    try {
      const results: IdentifyResultProps[] = [];

      const totalLength = identifyLayers.length;
      const identifyResult = await identifyChunk({
        geom,
        signal,
        connector,
        layers: identifyLayers.map((x) => x.id),
        onProgress: (progress) => {
          setIdentifyLoading((progress / totalLength) * 100);
        },
      });

      const errorLayers = identifyResult.problematic;
      if (errorLayers.length) {
        const layersStr = errorLayers.map((l, index) => (
          <React.Fragment key={l}>
            {index > 0 && ', '}
            <a href={`${baseUrl}/resource/${l}`}>{l}</a>
          </React.Fragment>
        ));
        const errorMessage = (
          <span>
            Не удалось выполнить идентификацию в{' '}
            {errorLayers.length > 1 ? 'слоях: ' : 'слое '}
            {layersStr}
          </span>
        );
        setIdentifyError(errorMessage);
      }

      const identifyItems: NgwIdentifyItem[] = identifyResult.successful;

      const layersBySource: Partial<
        Record<IdentifySourceType, IdentifyLayerItem[]>
      > = {};
      for (const identifyLayer of identifyLayers) {
        const source = identifyLayer.source;
        if (source) {
          const layersInGroup = layersBySource[source] || [];
          layersInGroup.push(identifyLayer);
          layersBySource[source] = layersInGroup;
        }
      }

      let identifyLayerInSource: IdentifySourceType;
      for (identifyLayerInSource in layersBySource) {
        const sourceLayers = layersBySource[identifyLayerInSource];
        if (sourceLayers) {
          const identifyResultProps: IdentifyResultProps = {
            identifyResult: [],
            identifyLayers: sourceLayers,
            identifySourceType: identifyLayerInSource,
          };
          for (const identifyItem of identifyItems) {
            const layerName = sourceLayers.find(
              (x) => x.id === identifyItem.resourceId,
            );
            if (layerName) {
              identifyResultProps.identifyResult.push({
                ...identifyItem,
                layerName: layerName.name,
              });
            }
          }
          if (identifyResultProps.identifyResult.length) {
            results.push(identifyResultProps);
          }
        }
      }

      setIdentifyResults(results);

      const duration = performance.now() - start;
      log.data.identifyItemsLength = identifyItems.length;
      logger?.info(`Identify completed in ${formatPerfTime(duration)}`, {
        ...log,
        operationId: 'identify-complete',
        duration,
      });
    } catch (er) {
      if (requestId === IDENTIFY_REQUEST_COUNT.current) {
        const duration = performance.now() - start;
        if (isObject(er) && er.name === 'CancelError') {
          logger?.info(`Identify stopped after ${formatPerfTime(duration)}`, {
            ...log,
            duration,
            operationId: 'identify-stopped',
          });
        } else {
          log.data.error = extractError(er);
          logger?.error(`Identify error after ${formatPerfTime(duration)}`, {
            ...log,
            duration,
            operationId: 'identify-error',
          });
        }
      }
    } finally {
      setIdentifyLoading(undefined);
    }
  }, [
    abort,
    baseUrl,
    bufferCoords,
    connector,
    identifyAbortControl,
    identifyLayers,
    identifySources,
    identifyType,
    logger,
    setIdentifyError,
    setIdentifyLoading,
    setIdentifyResults,
  ]);

  return {
    startIdentify,
  };
}
