import type { CellObject } from 'sheetjs-style';

export interface CreateStyleOptions {
  bold?: boolean;
  center?: boolean;
  right?: boolean;
  color?: string;
  highlight?: boolean;
}

export function createDateRow(dt?: Date) {
  dt = dt ?? new Date();
  const year = dt.getFullYear();
  const month = String(dt.getMonth() + 1).padStart(2, '0');
  const day = String(dt.getDate()).padStart(2, '0');
  const hours = String(dt.getHours()).padStart(2, '0');
  const minutes = String(dt.getMinutes()).padStart(2, '0');
  return ['Дата отчета', '', `${day}.${month}.${year} ${hours}:${minutes}`];
}

export function createStyle(
  { bold, center, highlight, right, color }: CreateStyleOptions = {
    bold: false,
  },
) {
  const style: CellObject['s'] = {
    font: {
      name: 'Calibri',
      sz: '12',
      bold,
    },
  };

  style.alignment = {
    horizontal: center ? 'center' : right ? 'right' : 'left',
    wrapText: true,
  };
  if (color && style.font) {
    style.font.color = { rgb: color };
  }
  if (highlight) {
    style.fill = { fgColor: { rgb: 'FFFF00' } };
  }
  return style;
}
export { createStyle as s };
