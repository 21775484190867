import type { IdentifySource, IdentifyType, SelectItem } from './interfaces';

export const baseUrl =
  process.env.NODE_ENV === 'development' ? '' : 'https://buryatiya.nextgis.com';

export const WEBMAP_ID = 1;

export const identifyTypeItems: SelectItem<IdentifyType>[] = [
  { label: 'Из таблицы', value: 'table' },
  { label: 'В точке', value: 'click' },
];

const identifySourceItems_: IdentifySource[] = [
  {
    label: 'Схема ООПТ',
    value: 'oopt',
    path: ['Республика Бурятия', 'Схема ООПТ', 'Границы'],
    selectable: false,
  },
  {
    label: 'Лесной план',
    value: 'les',
    path: ['Республика Бурятия', 'Лесной план', 'Лесничества (проект)'],
    fields: [{ keyname: 'KV', displayName: 'Кварталы' }],
    showLayers: false,
    selectable: false,
  },
  {
    label: 'Схема недропользования',
    value: 'subsurface',
    path: [
      'Республика Бурятия',
      'Схема недр',
      'Участки недр местного значения',
    ],
    fields: [{ keyname: 'Наиме', displayName: 'Наименование' }],
    selectable: false,
  },
  {
    label: 'Схема ТТП КМНС',
    value: 'ttp',
    fields: [{ keyname: 'NAME', displayName: 'Наименование' }],
    path: ['Республика Бурятия', 'ТТП КМНС'],
    selectable: false,
  },
  {
    label: 'Сельхозугодия',
    value: 'farmland',
    path: ['Республика Бурятия', 'Особо ценные с/х угодья'],
    selectable: false,
  },
  {
    label: 'Схема БПТ',
    value: 'bpt',
    path: ['Схема БПТ'],
    selectable: false,
  },
  {
    label: 'Схема охотугодий (архивная)',
    value: 'hunting-old',
    path: [
      'Республика Бурятия',
      'Схема охотугодий',
      'На 18.09.2023 г. (проект)',
    ],
    fields: [{ keyname: 'NAME', displayName: 'Наименование' }],
    showLayers: false,
    selectable: false,
  },
  {
    label: 'Схема охотугодий',
    value: 'hunting',
    path: ['Республика Бурятия', 'Схема охотугодий', 'ЕГРН'],
    fields: [{ keyname: 'NAME', displayName: 'Наименование' }],
    showLayers: false,
    selectable: false,
  },
  {
    label: 'Схема водопользования',
    value: 'water',
    fields: [{ keyname: 'NAME', displayName: 'Наименование' }],
    path: ['Республика Бурятия', 'Схема водопользования', 'ЗОУИТ'],
    selectable: false,
  },
  {
    label: 'Схема ТКО',
    value: 'tko',
    fields: [{ keyname: 'NAME', displayName: 'Наименование' }],
    path: ['Республика Бурятия', 'Схема ТКО'],
    selectable: false,
  },
  {
    label: 'Красная книга',
    value: 'redbook',
    path: ['Республика Бурятия', 'Красная книга'],
    selectable: false,
  },
  {
    label: 'Динамика',
    value: 'dynamic',
    fields: [{ keyname: 'NAME', displayName: 'Наименование' }],
    path: ['Республика Бурятия', 'Динамика', 'Республика Бурятия'],
    selectable: false,
  },
  {
    label: 'ОЭЗ ТРТ',
    value: 'oez',
    path: ['Республика Бурятия', 'ОЭЗ ТРТ Байкальская гавань'],
    selectable: false,
  },
];
export const identifySourceItems = identifySourceItems_.sort((a, b) =>
  a.label.localeCompare(b.label),
);
identifySourceItems.push({
  label: 'Предматериалы КЭО',
  value: 'all',
  path: [],
  showLayers: false,
  selectable: true,
});
