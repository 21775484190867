import { arrayCompare, getCoordinates } from '@nextgis/utils';
import area from '@turf/area';
import { buffer } from '@turf/buffer';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useConfigProvider } from '../../ConfigProvider';
import { featureFromCoords } from '../../utils/featureFromCoords';

import type { MutableRefObject } from 'react';
import type React from 'react';

import type {
  IdentifyLayerItem,
  IdentifyResultProps,
  IdentifySource,
  IdentifySourceType,
  IdentifyType,
} from '../../interfaces';

interface IdentifyContextProps {
  coords: number[][] | null;
  isLoading: boolean;
  bufferSize: number;
  bufferCoords: number[][] | null;
  identifyType: IdentifyType;
  reportLoading: boolean;
  identifyError: React.ReactNode;
  identifyLayers: IdentifyLayerItem[];
  identifyResults: IdentifyResultProps[] | null;
  identifyLoading: number | undefined;
  identifySources: IdentifySource[];
  identifySourceType: IdentifySourceType;
  reportAbortControl: MutableRefObject<AbortController | undefined>;
  identifyAbortControl: MutableRefObject<AbortController | undefined>;
  intersectionNotAllowed: boolean | string;
  setIdentifySourceType: React.Dispatch<
    React.SetStateAction<IdentifySourceType>
  >;
  setIdentifyResults: React.Dispatch<
    React.SetStateAction<IdentifyResultProps[] | null>
  >;
  setIdentifyLoading: React.Dispatch<React.SetStateAction<number | undefined>>;
  setReportLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIdentifyLayers: React.Dispatch<React.SetStateAction<IdentifyLayerItem[]>>;
  setIdentifyError: React.Dispatch<React.SetStateAction<React.ReactNode>>;
  setIdentifyType: React.Dispatch<React.SetStateAction<IdentifyType>>;
  setBufferSize: React.Dispatch<React.SetStateAction<number>>;
  setCoords: React.Dispatch<React.SetStateAction<number[][] | null>>;
  onGeom: (coordinates: number[][] | null) => void;
  abort: () => void;
}

const IdentifyContext = createContext<IdentifyContextProps | null>(null);

export function IdentifyProvider({ children }: { children: React.ReactNode }) {
  const [coords, setCoords] = useState<number[][] | null>(null);
  const [bufferSize, setBufferSize] = useState<number>(0);
  const [identifyType, setIdentifyType] = useState<IdentifyType>('table');
  const [identifySourceType, setIdentifySourceType] =
    useState<IdentifySourceType>('all');
  const [identifyResults, setIdentifyResults] = useState<
    IdentifyResultProps[] | null
  >(null);
  const [identifyError, setIdentifyError] = useState<React.ReactNode>();
  const [identifyLoading, setIdentifyLoading] = useState<number>();
  const [reportLoading, setReportLoading] = useState<boolean>(false);
  const [identifyLayers, setIdentifyLayers] = useState<IdentifyLayerItem[]>([]);

  const { identifySourceItems } = useConfigProvider();

  const isLoading = useMemo(() => {
    return reportLoading || (identifyLoading !== undefined ? true : false);
  }, [identifyLoading, reportLoading]);

  const identifyAbortControl = useRef<AbortController>();
  const reportAbortControl = useRef<AbortController>();

  const identifySources = useMemo(() => {
    if (identifySourceType === 'all') {
      return identifySourceItems;
    }
    return identifySourceItems.filter((x) => identifySourceType === x.value);
  }, [identifySourceItems, identifySourceType]);

  const bufferGeom = useMemo(() => {
    if (bufferSize && coords) {
      return buffer(featureFromCoords(coords), bufferSize, {
        units: 'kilometers',
      });
    }
  }, [coords, bufferSize]);

  const bufferCoords = useMemo(() => {
    if (bufferGeom) {
      return getCoordinates(bufferGeom);
    }
    return coords;
  }, [coords, bufferGeom]);

  const onGeom = useCallback((coordinates: number[][] | null) => {
    if (coordinates) {
      if (coordinates.length > 2) {
        const polygon = [...coordinates];
        const firstCoord = polygon[0];
        const lastCoords = polygon[polygon.length - 1];
        if (!arrayCompare(firstCoord, lastCoords)) {
          polygon.push(firstCoord);
        }
        coordinates = polygon;
      }
    }
    setCoords(coordinates);
  }, []);

  const intersectionNotAllowed = useMemo<boolean | string>(() => {
    if (bufferGeom) {
      return area(bufferGeom) > 1000000000
        ? 'Площадь не должна превышать 100,000 га.'
        : false;
    }
    return false;
  }, [bufferGeom]);

  const abort = useCallback(() => {
    if (identifyAbortControl.current) {
      identifyAbortControl.current.abort();
    }
    identifyAbortControl.current = undefined;
  }, []);

  return (
    <IdentifyContext.Provider
      value={{
        coords,
        isLoading,
        bufferSize,
        identifyType,
        bufferCoords,
        reportLoading,
        identifyError,
        identifyLayers,
        identifyResults,
        identifyLoading,
        identifySources,
        reportAbortControl,
        identifySourceType,
        identifyAbortControl,
        intersectionNotAllowed,
        setIdentifySourceType,
        setIdentifyLoading,
        setIdentifyResults,
        setIdentifyLayers,
        setReportLoading,
        setIdentifyError,
        setIdentifyType,
        setBufferSize,
        setCoords,
        onGeom,
        abort,
      }}
    >
      {children}
    </IdentifyContext.Provider>
  );
}

export const useIdentifyContext = () => {
  const context = useContext(IdentifyContext);
  if (!context) {
    throw new Error(
      'useIdentifyContext must be used within an IdentifyProvider',
    );
  }
  return context;
};
