import { polygonFromCoords } from './polygonFromCoords';

import type { Feature, Point, Polygon } from 'geojson';

export function featureFromCoords(
  coords: number[][],
): Feature<Polygon | Point> {
  if (coords.length > 2) {
    return polygonFromCoords(coords);
  } else {
    const [lng, lat] = coords[0];
    const pointFeature: Feature<Point> = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Point',
        coordinates: [lng, lat],
      },
    };
    return pointFeature;
  }
}
