import chunk from 'lodash-es/chunk';

import { makeIdentify } from '../../../utils/makeIdentify';

import type NgwConnector from '@nextgis/ngw-connector';
import type { NgwIdentifyItem } from '@nextgis/ngw-kit';

export interface IdentifyChunkParams {
  layers: number[];
  chunkSize?: number;
  geom: number[][];
  signal: AbortSignal;
  onProgress?: (processedLayers: number) => void;
  connector: NgwConnector;
}

export interface IdentifyChunkOutput {
  successful: NgwIdentifyItem[];
  problematic: number[];
}

export async function identifyChunk({
  geom,
  signal,
  layers,
  chunkSize = 300,
  connector,
  onProgress,
  processedLayers = 0,
}: IdentifyChunkParams & {
  processedLayers?: number;
}): Promise<IdentifyChunkOutput> {
  if (chunkSize <= 0) return { successful: [], problematic: [] };

  const layerChunks = chunk(layers, chunkSize);
  const allResults: IdentifyChunkOutput = {
    successful: [],
    problematic: [],
  };

  for (const layerChunk of layerChunks) {
    try {
      const identifyPart = await makeIdentify({
        geom,
        signal,
        connector,
        layers: layerChunk,
      });
      processedLayers += layerChunk.length;
      onProgress?.(processedLayers);
      allResults.successful.push(...identifyPart);
    } catch (er) {
      if ('name' in (er as Error) && (er as Error).name === 'AbortError') {
        throw er;
      }
      // If an error occurs with a chunk, reduce the chunk size and try again.
      // This recursion helps to identify the problematic layer rather than failing the entire chunk.
      if (chunkSize > 1) {
        const nextChunkSize = Math.floor(chunkSize / 10) || 1;
        const chunkResults = await identifyChunk({
          geom,
          signal,
          layers: layerChunk,
          connector,
          chunkSize: nextChunkSize,
          onProgress,
          processedLayers,
        });
        allResults.successful.push(...chunkResults.successful);
        chunkResults.problematic.forEach((layerId) =>
          allResults.problematic.push(layerId),
        );
      } else {
        allResults.problematic.push(...layerChunk);
      }
    }
  }

  return allResults;
}
