import { useNgwMapContext } from '@nextgis/react-ngw-map';
import { Typography } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useIdentifyContext } from '../../IdentifyContext';
import { PanelText } from '../../PanelText';

import { CoordinatesInput } from './CoordinatesInput';

import type { LngLatArray } from '@nextgis/utils';
import type { MapClickEvent } from '@nextgis/webmap';

const { Text } = Typography;

export function PointIdentifyPanel() {
  const { onGeom, isLoading } = useIdentifyContext();
  const { ngwMap } = useNgwMapContext();

  const [lngLat, setLngLat] = useState<LngLatArray | null>(null);

  const onMapClick = useRef((e: MapClickEvent) => {
    const [lng, lat] = e.lngLat;
    const toSet = [Math.abs(Number(lng)) % 360, lat];
    setLngLat(toSet);
  });

  const disableIdentify = useCallback(() => {
    ngwMap.setCursor('');
    ngwMap.emitter.off('click', onMapClick.current);
  }, [ngwMap]);

  const startIdentify = useCallback(() => {
    disableIdentify();
    ngwMap.setCursor('crosshair');
    ngwMap.emitter.on('click', onMapClick.current);
  }, [ngwMap, disableIdentify]);

  const stopIdentify = useCallback(() => {
    disableIdentify();
    setLngLat(null);
  }, [disableIdentify]);

  useEffect(() => {
    if (!isLoading) {
      startIdentify();
    }
    return () => {
      disableIdentify();
    };
  }, [disableIdentify, startIdentify, isLoading]);

  useEffect(() => {
    if (lngLat) {
      onGeom([lngLat]);
    } else {
      onGeom(null);
    }
  }, [lngLat, onGeom]);

  useEffect(() => {
    startIdentify();

    return () => {
      stopIdentify();
    };
  }, [startIdentify, stopIdentify]);

  return (
    <div>
      <CoordinatesInput
        disabled={isLoading}
        lngLat={lngLat}
        onChange={setLngLat}
      ></CoordinatesInput>
      <PanelText>
        <Text type="secondary" italic>
          Кликните по карте для установки координат
        </Text>
      </PanelText>
    </div>
  );
}
