import {
  featureLayerIdentify,
  fetchNgwLayerItems,
  getIdentifyItems,
} from '@nextgis/ngw-kit';

import { polygonFromCoords } from './polygonFromCoords';

import type { FeatureLayersIdentifyItems } from '@nextgis/ngw-connector';
import type { FeatureLayerIdentifyOptions } from '@nextgis/ngw-kit';
import type { LineString, Point, Polygon } from 'geojson';

type SimpleGeom = Point | Polygon | LineString;

export async function makeIdentify({
  geom,
  signal,
  layers,
  connector,
}: FeatureLayerIdentifyOptions) {
  const resp = await featureLayerIdentify({
    connector,
    layers,
    signal,
    geom,
  });
  const overLimitResources: number[] = [];
  for (const id in resp) {
    const item_ = resp[id];
    if (id !== 'featureCount') {
      const item = item_ as FeatureLayersIdentifyItems;
      if (item.featureCount === 10) {
        overLimitResources.push(Number(id));
      }
    }
  }

  if (overLimitResources.length) {
    const bbox = (await import('@turf/bbox')).default;
    const booleanIntersects = (await import('@turf/boolean-intersects'))
      .default;

    const g = geom as number[][];
    const polygonToIntersect = polygonFromCoords(g);
    const bboxFromPoly = bbox(polygonToIntersect);

    for (const id of overLimitResources) {
      const item = resp[id] as FeatureLayersIdentifyItems;
      const layerItems = await fetchNgwLayerItems<SimpleGeom>({
        connector,
        resourceId: Number(id),
        extensions: false,
        intersects: bboxFromPoly,
        signal,
      });
      const features = [];
      for (const x of layerItems) {
        const itemGeoms: SimpleGeom[] = [];
        if (x.geom.type.toLocaleLowerCase().indexOf('multi') !== -1) {
          for (const g of x.geom.coordinates) {
            itemGeoms.push({
              type: x.geom.type.replace('Multi', ''),
              coordinates: g,
            } as SimpleGeom);
          }
        } else {
          itemGeoms.push(x.geom);
        }
        for (const itemGeom of itemGeoms) {
          if (booleanIntersects(itemGeom, polygonToIntersect)) {
            features.push({
              fields: x.fields,
              id: x.id,
              label: `#${x.id}`,
              layerId: Number(id),
              parent: '0',
            });
            break;
          }
        }
      }
      item.features = features;
    }
  }

  const identifyItems = getIdentifyItems(
    {
      ...resp,
      resources: layers,
    },
    true,
  );
  return identifyItems;
}
