import type { Feature, MultiPolygon, Polygon, Position } from 'geojson';

export function normalizeGeometry<
  T extends Feature<Polygon | MultiPolygon> | Polygon | MultiPolygon =
    | Feature<Polygon | MultiPolygon>
    | Polygon
    | MultiPolygon,
>(geom: T, factor = 5): T {
  function fix<
    P extends Position[][] | Position[][][] = Position[][] | Position[][][],
  >(coordinates: P): P {
    return coordinates.map((part) => {
      return part.map((ring) =>
        ring.map((lngLat) => {
          if (Array.isArray(lngLat)) {
            const [lng, lat] = lngLat;
            return [
              parseFloat(lng.toFixed(factor)),
              parseFloat(lat.toFixed(factor)),
            ];
          } else {
            return parseFloat(lngLat.toFixed(factor));
          }
        }),
      );
    }) as P;
  }

  if (geom.type === 'Feature') {
    geom.geometry.coordinates = fix(geom.geometry.coordinates);
  } else if (geom.coordinates) {
    geom.coordinates = fix(geom.coordinates);
  }

  return geom;
}
