import { ConsoleEngine, Logging, NgLogEngine } from '@nextgis/logging';
import { ConfigProvider as AntdConfigProvider } from 'antd';
import ruRu from 'antd/locale/ru_RU';

import pkg from '../package.json';

import { PrpMap } from './components/PrpMap';
import { baseUrl, identifySourceItems, WEBMAP_ID } from './config';
import { ConfigProvider } from './ConfigProvider';

export default function App() {
  const logger = new Logging({
    eachLog: (msg, opt = {}) => {
      opt.data = opt.data || {};
      opt.data.version = pkg.version;
      return opt;
    },
    engines: [
      new ConsoleEngine({ enabled: process.env.NODE_ENV === 'development' }),
      new NgLogEngine({
        clientId: 'd425362d-fa60-4591-8c99-7796729fba1a',
        nglogUrl: 'https://nglog.staging.nextgis.com',
        stopOnConnectionError: true,
        enabled: process.env.NODE_ENV !== 'development',

        // clientId: 'bbb730cc-565b-4501-bc50-c2fa94ea8886',
        // nglogUrl: 'http://localhost:8081',
      }),
    ],
  });

  logger.info('The app is started', {
    operationId: 'app-started',
  });

  return (
    <AntdConfigProvider locale={ruRu}>
      <ConfigProvider
        identifySourceItems={identifySourceItems}
        baseUrl={baseUrl}
        webMapId={WEBMAP_ID}
        logger={logger}
        customReportSheets={[
          {
            name: 'Реквизиты',
            loader: async ({ dt }) =>
              import('./report/createDetailsSheet').then(
                ({ createDetailsSheet }) => createDetailsSheet(dt),
              ),
          },
        ]}
      >
        <PrpMap />
      </ConfigProvider>
    </AntdConfigProvider>
  );
}
