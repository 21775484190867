import type {
  WebMapItemGroupRead,
  WebMapItemLayerRead,
  WebMapRead,
} from '@nextgisweb/webmap/type/api';

import type { IdentifyLayerItem } from '../interfaces';

export function getIdentifyLayers(
  webmap: WebMapRead,
  path: string[],
): IdentifyLayerItem[] {
  const identifyLayers: IdentifyLayerItem[] = [];

  const appendIdentifyLayers = (
    treeItem: WebMapItemGroupRead | WebMapItemLayerRead,
  ) => {
    if (treeItem.item_type === 'layer') {
      const id = treeItem.style_parent_id;
      if (id !== null) {
        identifyLayers.push({
          name: treeItem.display_name || String(id),
          styleId: treeItem.layer_style_id,
          id,
        });
      }
    } else if (treeItem.item_type === 'group') {
      for (const c of treeItem.children) {
        appendIdentifyLayers(c);
      }
    }
  };

  const rootPath = path[0];
  const pathInItemGroup = path.slice(1, path.length);
  const oopt = webmap.root_item.children.find(
    (x) => x.display_name === rootPath,
  );
  if (oopt && oopt.item_type === 'group') {
    if (pathInItemGroup.length) {
      let currentGroup: WebMapItemGroupRead | undefined = oopt;
      for (const p of pathInItemGroup) {
        if (currentGroup) {
          const children:
            | WebMapItemGroupRead
            | WebMapItemLayerRead
            | undefined = currentGroup.children.find(
            (x) => x.display_name === p,
          );
          if (children && children.item_type === 'group') {
            currentGroup = children;
          } else {
            currentGroup = undefined;
          }
        }
      }
      if (currentGroup) {
        appendIdentifyLayers(currentGroup);
      }
    } else {
      appendIdentifyLayers(oopt);
    }
  }

  return identifyLayers;
}
