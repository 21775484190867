import ReactNgwMap from '@nextgis/react-ngw-leaflet';
import { MapControl } from '@nextgis/react-ngw-map';
import { useState } from 'react';
import styled from 'styled-components';

import { useConfigProvider } from '../ConfigProvider';

import { IdentifyPanel } from './Identify/Identify';

const IdentifyPanelWrapper = styled.div({
  background: 'white',
  padding: '1rem',
  width: '390px',
});

export function PrpMap() {
  const [identifyMode] = useState(true);
  const { connector } = useConfigProvider();

  return (
    <ReactNgwMap
      id="map"
      osm
      connector={connector}
      bounds={[102.152, 49.315, 113.84, 56.677]}
    >
      {identifyMode && (
        <MapControl position="top-right" margin={true}>
          <IdentifyPanelWrapper
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
              e.stopPropagation()
            }
          >
            <IdentifyPanel />
          </IdentifyPanelWrapper>
        </MapControl>
      )}
    </ReactNgwMap>
  );
}
