import { InputNumber } from 'antd';

import { useIdentifyContext } from './IdentifyContext';

export function IdentifySettings() {
  const { coords, bufferSize, setBufferSize, isLoading } = useIdentifyContext();

  if (coords) {
    return (
      <InputNumber
        disabled={isLoading}
        style={{ width: '100%' }}
        size="small"
        addonAfter="км"
        addonBefore="Буфер"
        value={bufferSize}
        onChange={(v: number | null) => {
          if (typeof v === 'number') {
            setBufferSize(v);
          }
        }}
      />
    );
  }
  return null;
}
