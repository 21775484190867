import { Button, Modal } from 'antd';
import React, { Suspense, useEffect, useState } from 'react';

import { useIdentifyContext } from '../../IdentifyContext';

const CoordinatesTable = React.lazy(() => import('./CoordinatesTable'));

export function TableIdentifyPanel() {
  const { onGeom } = useIdentifyContext();

  const [isModalOpen, setIsModalVisible] = useState(false);
  const [coordinates, setCoordinates] = useState<number[][]>([]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    const coords = [...coordinates].filter(Boolean);
    onGeom(coords);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const stopIdentify = () => {
    setCoordinates([]);
  };

  useEffect(() => {
    return () => {
      stopIdentify();
    };
  }, []);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button type="primary" block onClick={showModal}>
          Открыть таблицу координат
        </Button>
      </div>

      <Modal
        title="Таблица координат"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Suspense fallback={<div>Загрузка таблицы...</div>}>
          <CoordinatesTable
            coordinates={coordinates}
            onChange={setCoordinates}
          ></CoordinatesTable>
        </Suspense>
      </Modal>
    </>
  );
}
