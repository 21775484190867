import NgwConnector from '@nextgis/ngw-connector';
import { createContext, useContext, useMemo } from 'react';

import type { Logging } from '@nextgis/logging';
import type React from 'react';
import type { WorkSheet } from 'sheetjs-style';

import type { IdentifySource } from './interfaces';

interface ConfigContextProps {
  baseUrl: string;
  webMapId: number;
  logger?: Logging<unknown>;
  identifySourceItems: IdentifySource[];
  maxBaselayersDisplay?: number;
  customReportSheets?: {
    name: string;
    loader: ({ dt }: { dt: Date }) => Promise<WorkSheet>;
  }[];
}

interface ConfigContextValue extends ConfigContextProps {
  connector: NgwConnector;
  maxBaselayersDisplay: number;
}

const ConfigContext = createContext<ConfigContextValue | undefined>(undefined);

export function ConfigProvider({
  logger,
  children,
  baseUrl,
  webMapId,
  customReportSheets,
  identifySourceItems,
  maxBaselayersDisplay,
}: ConfigContextProps & { children: React.ReactNode }) {
  const value = useMemo<ConfigContextValue>(
    () => ({
      maxBaselayersDisplay: maxBaselayersDisplay ?? 100,
      connector: new NgwConnector({
        baseUrl,
      }),
      logger,
      baseUrl,
      webMapId,
      customReportSheets,
      identifySourceItems,
    }),
    [
      logger,
      baseUrl,
      identifySourceItems,
      customReportSheets,
      maxBaselayersDisplay,
      webMapId,
    ],
  );

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
}

export const useConfigProvider = () => {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error('useConfigProvider must be used within an ConfigProvider');
  }
  return context;
};
